<style>
#locationsMap {
  width: 100%;
  height: 100%;
}
</style>
<template>
  <v-responsive id="mapContainer" :aspect-ratio="1.5">
    <link rel="stylesheet" type="text/css" href="https://js.api.here.com/v3/3.1/mapsjs-ui.css" />
    <div ref="map" id="locationsMap"></div>
  </v-responsive>
</template>
<script>
import H from '@here/maps-api-for-javascript'
import AddressMixin from '../mixins/AddressMixin'
import axios from 'axios'

export default {
  name: 'LocationsMap',
  mixins: [AddressMixin],
  components: {},
  props: {
    locations: {
      required: true,
      type: Array
    },
    // provide this prop if you want to center the map on a point and draw an iso line around it
    isoCenter: {
      required: false,
      type: Object
    }
  },
  data: () => ({
    map: null,
    platform: null,
    markerGroup: null,
    center: null
  }),
  computed: {
    isoLineStyle () {
      return { strokeColor: this.$vuetify.theme.currentTheme.accent, fillColor: 'rgba(43,217,235,.2)' }
    }
  },
  mounted () {
    // initialize HERE mapping service platform
    this.platform = new H.service.Platform({
      apikey: process.env.VUE_APP_HERE_API_KEY
    })

    this.initializeHereMap()
  },
  beforeDestroy () {
    delete this.isoCenter.position
  },
  methods: {
    initializeHereMap () {
      const mapContainer = this.$refs.map

      // Obtain the default map types from the platform object
      const maptypes = this.platform.createDefaultLayers()

      // Instantiate (and display) a map object:
      this.map = new H.Map(mapContainer, maptypes.vector.normal.map, {
        zoom: 10,
        center: { lat: 35.69913, lng: -90.13671 } // default center, will resize to new boundary or isoCenter
      })

      addEventListener('resize', () => this.map.getViewPort().resize())

      // add behavior control
      // eslint-disable-next-line no-new
      new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map))

      // add UI
      H.ui.UI.createDefault(this.map, maptypes)

      this.setLocationMarkers()
    },
    markerIcon (text) {
      const svgMarkup = '<svg width="24" height="24" ' +
        'xmlns="http://www.w3.org/2000/svg">' +
        '<rect stroke="white" fill="#1b468d" x="1" y="1" width="22" ' +
        'height="22" /><text x="12" y="18" font-size="12pt" ' +
        'font-family="Arial" font-weight="bold" text-anchor="middle" ' +
        `fill="white">${text}</text></svg>`

      // Create an icon, an object holding the latitude and longitude, and a marker:
      return new H.map.Icon(svgMarkup)
    },
    markerClicked (marker) {
      this.$emit('click:marker', marker)
    },
    locationMarker (position, text, location) {
      const marker = new H.map.Marker(position, {
        icon: this.markerIcon(text),
        data: {
          location
        }
      })

      marker.addEventListener('tap', (event) => {
        this.markerClicked(event.target.getData().location)
      })

      return marker
    },
    setLocationMarkers () {
      // add markers and set view bounds
      this.markerGroup = new H.map.Group()
      const promises = []

      if (this.isoCenter) {
        const promise = this
          .getAddressCoordinates(this.isoCenter)
          .then(position => {
            if (position) {
              this.markerGroup.addObject(this.locationMarker(position, '*', this.isoCenter))
              this.center = position
            }
          })
          .catch(error => console.error(error))
        promises.push(promise)
      }

      this.locations.forEach((location, index) => {
        index = index + 1
        if (!location.position) {
          const promise = this
            .getAddressCoordinates(location)
            .then(position => {
              location.position = position
              this.markerGroup.addObject(this.locationMarker(location.position, index, location))
            })
            .catch(error => console.error(error))

          promises.push(promise)
        } else {
          this.markerGroup.addObject(this.locationMarker(location.position, index, location))
        }
      })

      // wait for all the locations to be geocoded
      Promise.all(promises).then(() => {
        this.map.addObject(this.markerGroup)

        if (this.center !== null) {
          this.getIsoline(this.center)
          this.map.getViewModel().setLookAtData({
            position: this.center,
            zoom: 9
          })
        } else {
          this.map.getViewModel().setLookAtData({
            bounds: this.markerGroup.getBoundingBox()
          })
        }
      })
    },
    async getAddressCoordinates (address) {
      return new Promise((resolve, reject) => {
        const formattedAddress = this.formatAddress(address)
        const service = this.platform.getSearchService()
        service.geocode({
          q: formattedAddress
        }, (result) => {
          if (result.items.length > 0) {
            resolve(result.items[0].position)
          }

          reject(new Error('unable to GeoCode address ' + formattedAddress))
        }, error => {
          reject(error)
        })
      })
    },
    async getIsoline (position) {
      const url = `https://isoline.router.hereapi.com/v8/isolines?apiKey=${process.env.VUE_APP_HERE_API_KEY}&transportMode=car&range[type]=time&range[values]=1800&origin=${position.lat},${position.lng}`
      try {
        const response = await axios.get(url)
        const isolines = response.data.isolines
        if (isolines.length > 0) {
          const polygons = isolines[0].polygons

          polygons.forEach(p => {
            const linestring = H.geo.LineString.fromFlexiblePolyline(p.outer)
            this.map.addObject(new H.map.Polygon(linestring, { style: this.isoLineStyle }))
          })
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
