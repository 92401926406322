<style>
.v-stepper__wrapper {
    overflow: unset !important;
    transition: none;
}
.v-stepper__header {
    padding: 0 24px;
    flex-wrap: nowrap;
}

.v-stepper__step {
    padding: 8px 0 !important;
}
</style>
<template>
  <v-stepper v-model="step" flat outlined :alt-labels="$vuetify.breakpoint.mdAndUp" non-linear horizontal>
    <v-stepper-header>
      <v-stepper-step v-for="(step, index) in visibleSteps" :step="index + 1" :editable="step.editable" :key="index">
        {{ step.text }}
      </v-stepper-step>

    </v-stepper-header>
    <v-stepper-items>
      <v-stepper-content v-if="stepVisible('Begin')" :step="stepValue('Begin')">
        <v-row class="pa-4">
          <v-col>
            <v-hover v-slot="{ hover }">
              <v-card :elevation="hover ? 8 : 2" :class="{ 'on-hover': hover }" @click="handleUserContextSelect('patient')">
                <v-card-text class="text-center">
                  <v-icon x-large>fas fa-user-injured</v-icon>
                  <p class="pt-4 text-body-1">I am requesting an appointment for myself</p>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
          <v-col>
            <v-hover v-slot="{ hover }">
              <v-card :elevation="hover ? 8 : 2" :class="{ 'on-hover': hover }" @click="handleUserContextSelect('attorney')">
                <v-card-text class="text-center">
                  <v-icon x-large>fas fa-user-tie</v-icon>
                  <p class="pt-4 text-body-1">I am requesting an appointment on behalf of a client</p>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-stepper-content>

      <v-stepper-content v-if="stepVisible('Patient')" :step="stepValue('Patient')">
        <h2 class="mb-2"><span v-if="userContext === 'attorney'">Patient</span> Contact Information</h2>
        <v-form ref="patientForm" v-model="patientForm">
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field v-model="newCase.patient.first_name"
                            label="* First Name"
                            :rules="[rules.required]"
                            filled dense hide-details="auto"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="newCase.patient.last_name"
                            label="* Last Name"
                            :rules="[rules.required]"
                            filled dense hide-details="auto"></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="4">
              <DatePicker
                ref="datepicker"
                :date="newCase.patient.dob"
                :default-to-today="false"
                label="* Date of Birth"
                @date-change="setDobDate"
                :dateValid="dobValid"
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field v-model="newCase.patient.email"
                            type="email"
                            label="Email"
                            :rules="[rules.optionalEmail]"
                            filled dense hide-details="auto" />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="newCase.patient.main_phone"
                            type="text"
                            label="* Main Phone"
                            :rules="[rules.required, rules.phone]"
                            filled dense hide-details="auto"
                            v-mask="'(###) ###-####'" />
            </v-col>
          </v-row>

          <h2 class="mt-4 mb-2">Home Address</h2>

          <v-row dense>
            <v-col cols="12" md="8">
              <v-text-field v-model="newCase.patient.address.address_line1"
                            label="* Street Address"
                            filled dense hide-details="auto"
                            :rules="[rules.required]" />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="newCase.patient.address.address_line2"
                            label="Address Line 2"
                            filled dense hide-details="auto" />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="newCase.patient.address.city"
                            label="* City"
                            filled dense hide-details="auto"
                            :rules="[rules.required]" />
            </v-col>
            <v-col cols="4">
              <StatePicker :state="newCase.patient.address.state" :rules="[rules.required]" @state-change="setState"/>
            </v-col>
            <v-col>
              <v-text-field v-model="newCase.patient.address.zip"
                            label="* Zip Code"
                            filled dense hide-details="auto"
                            :rules="[rules.zip, rules.required]" />
            </v-col>
          </v-row>

          <h2 class="mt-4 mb-2">Accident Details</h2>
          <v-row>
            <v-col cols="4">
              <DatePicker
                ref="datepicker"
                :date="newCase.date_of_accident"
                :default-to-today="false"
                label="* Date of Accident"
                @date-change="setAccidentDate"
                :dateValid="accidentDateValid"
              />
            </v-col>
          </v-row>
          <v-textarea v-model="newCase.description"
                      class="mt-2"
                      label="* Description of the Accident"
                      :rules="[rules.required]"
                      filled dense hide-details="auto" />

          <v-checkbox v-if="userContext === 'patient'" v-model="patientHasAttorney" label="Are you working with an attorney?" />

          <div class="mt-6 text-right">
            <v-btn v-if="stepVisible('Begin')" text @click="step--" class="mr-2">Back</v-btn>
            <v-btn color="primary" @click="validatePatientForm">
              Continue
            </v-btn>
          </div>
        </v-form>
      </v-stepper-content>

      <v-stepper-content v-if="stepVisible('Firm Members')" :step="stepValue('Firm Members')">
        <MembersForm ref="membersForm" :passed-firm="this.newCase.firm" @firm="reassignFirm"/>
        <div class="mt-6 text-right">
          <v-btn text @click="step--" class="mr-2">Back</v-btn>
          <v-btn color="primary" @click="validateLegalFirmForm">
            Continue
          </v-btn>
        </div>
      </v-stepper-content>

      <v-stepper-content v-if="stepVisible('Location')" :step="stepValue('Location')">
        <h1>Choose Location</h1>

        <p>Patient Address: {{ formatAddress(newCase.patient.address) }}</p>

        <div v-if="locations.length > 0" class="d-md-flex">
          <div>
            <v-list>
              <v-list-item-group v-model="selectedLocation" color="primary">
                <v-list-item v-for="(location, index) in locations" :key="location.id" two-line>
                  <v-list-item-icon class="mr-0">{{ index + 1}}:</v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ location.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ formatAddress(location.address) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
          <div :class="{'pl-4': $vuetify.breakpoint.mdAndUp}" class="flex-grow-1 d-flex justify-center flex-column">
<!--            Not sure what to infer from this phrasing? -->
<!--            <p>The light blue line indicates the average travel distance by car within 30 minutes</p>-->
            <p>Routes marked with a light blue line indicate a driving distance of 30 minutes or less.</p>
            <locations-map v-if="step === stepValue('Location')"
                           :locations="locationAddresses"
                           :iso-center="newCase.patient.address"

                           @click:marker="handleLocationIconClick" />
          </div>
        </div>

        <div class="text-right mt-6">
          <v-btn text @click="step--" class="mr-2">Back</v-btn>
          <v-btn color="primary" @click="validateLocationForm">
            Continue
          </v-btn>
        </div>
      </v-stepper-content>

      <v-stepper-content v-if="stepVisible('Review')" :step="stepValue('Review')">
        <h1>Review</h1>
        <h2 class="mt-8">Patient</h2>
        <div class="mb-0">{{ newCase.patient.first_name }} {{ newCase.patient.last_name }}</div>
        <div class="mb-0">Email: {{ newCase.patient.email }}</div>
        <div class="mb-0">Phone: {{ newCase.patient.main_phone }}</div>
        <div>{{ formatAddress(newCase.patient.address) }}</div>

        <h2 class="mt-8">Accident Details</h2>
        <div>{{ formatDate(newCase.date_of_accident) }}</div>
        <div>{{ newCase.description }}</div>

        <h2 class="mt-8">Legal Firm Information</h2>
        <div class="my-3 font-weight-bold">LEGAL FIRM: {{newCase.firm.firm_name}}</div>
        <div v-if="newCase.firm.firm_members.length === 0">
          <div class="font-weight-bold"><span>NO MEMBERS</span></div>
        </div>
        <v-row dense>
          <v-col v-for="(member, index) in newCase.firm.firm_members" :key="index" :cols="6" class="mb-3">
            <div>
              <div class="font-weight-bold">MEMBER #{{index + 1}}</div>
              <div>{{member.member_name}}, {{member.role === Members.ATTORNEY.value ? Members.ATTORNEY.text : Members.CASE_MANAGER.text}}</div>
              <div>Phone: {{member.member_phone}}</div>
              <div>Email: {{member.member_email}}</div>
              <div v-if="member.primary_contact" class="font-weight-bold">Primary Point of Contact</div>
            </div>
          </v-col>
        </v-row>

        <h2 class="mt-8">Healthcare Location</h2>
        <p class="mb-0">{{ newCase.location.name }}</p>
        <p>{{ formatAddress(newCase.location.address) }}</p>
        <div class="mt-6 text-right">
          <v-btn text @click="step--" class="mr-2">Back</v-btn>
          <v-btn @click="save" color="primary">
            Submit
          </v-btn>
        </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import event, { Events } from '../../event'
import { mapState } from 'vuex'
import AddressMixin from '../../mixins/AddressMixin'
import { formatDate } from '@/js/PatientIntake/functions'
import RulesMixin from '../../mixins/RulesMixin'
import LocationsMap from '../LocationsMap'
import DatePicker from '@/components/PatientIntake/DatePicker'
import StatePicker from '@/components/FormFields/StatePicker'
import DateMixin from '@/mixins/DateMixin'
import MembersForm from '@/components/FirmMembers/MembersForm'
import { Members } from '@/js/Members'
import structuredClone from '@ungap/structured-clone'

export default {
  name: 'NewCaseForm',
  mixins: [AddressMixin, RulesMixin, DateMixin],
  components: { MembersForm, DatePicker, LocationsMap, StatePicker },
  props: {
    internal: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      // TODO change to step 1
      step: 1,
      steps: [
        {
          text: 'Begin',
          editable: true,
          show: () => !this.internal
        },
        {
          text: 'Patient',
          editable: true,
          show: () => true
        },
        {
          text: 'Firm Members',
          editable: true,
          show: () => this.showAttorneyStep
        },
        {
          text: 'Location',
          editable: true,
          show: () => true
        },
        {
          text: 'Review',
          editable: true,
          show: () => true
        }
      ],
      firmForm: true,
      patientForm: true,
      userContext: 'patient',
      patientHasAttorney: false,
      dobValid: true,
      accidentDateValid: true,
      newCase: {
        date_of_accident: null,
        description: null,
        patient: {
          first_name: null,
          last_name: null,
          dob: null,
          main_phone: null,
          email: null,
          address: {
            address_line1: null,
            address_line2: null,
            city: null,
            state: null,
            zip: null
          }
        },
        firm: {
          organization_id: null,
          firm_name: null,
          case_managers: [],
          firm_members: [],
          members: []
        },
        location: {
          id: null,
          name: null,
          address: {}
        }
      },
      attorneySearch: null,
      selectedLocation: 0,
      Members
    }
  },
  computed: {
    ...mapState({
      locations: state => state.Organization.locations
    }),
    locationAddresses () {
      return this.locations.map(l => l.address)
    },
    showAttorneyStep () {
      return this.userContext === 'attorney' || this.patientHasAttorney || this.internal
    },
    visibleSteps () {
      return this.steps.filter(step => step.show())
    }
  },
  created () {
    this.$store.dispatch('Organization/getLocations')
  },
  methods: {
    formatDate,
    stepVisible (stepLabel) {
      const step = this.steps.find(s => s.text === stepLabel)
      if (step) {
        return step.show()
      }

      return false
    },
    stepValue (stepLabel) {
      const index = this.visibleSteps.findIndex(s => s.text === stepLabel)
      return index + 1
    },
    handleUserContextSelect (type) {
      this.userContext = type
      this.step = 2
    },
    validatePatientForm () {
      this.$refs.patientForm.validate()
      this.dobValid = this.validateDate(this.newCase.patient.dob)
      this.accidentDateValid = this.validateDate(this.newCase.date_of_accident)
      if (this.patientForm && this.dobValid !== 'Required' && this.accidentDateValid !== 'Required') {
        this.step = this.stepValue('Patient') + 1
      }
    },
    validateLegalFirmForm () {
      this.$refs.membersForm.validate()
      if (!this.$refs.membersForm || (this.$refs.membersForm.firm.firm_members.length > 0 && !this.$refs.membersForm.primaryContact) || !this.$refs.membersForm.validMembers) {
        return
      }
      this.step = this.stepValue('Firm Members') + 1
    },
    reassignFirm (value) {
      this.newCase.firm = structuredClone(value)
    },
    validateLocationForm () {
      if (this.selectedLocation < 0 || this.selectedLocation > this.locations.length) {
        event.emit(Events.ERROR, 'Please select a valid location from the list')
        return
      }
      this.newCase.location = this.locations[this.selectedLocation]
      this.step = this.stepValue('Location') + 1
    },
    async save () {
      await window.axios.post('/v1/case', this.newCase)
      this.$emit('created')
    },
    handleLocationIconClick (location) {
      const locationIndex = this.locations.findIndex(l => l.address.id === location.id)
      if (locationIndex > -1) {
        this.selectedLocation = locationIndex
      }
    },
    setDobDate (value) {
      this.newCase.patient.dob = value
    },
    setAccidentDate (value) {
      this.newCase.date_of_accident = value
    },
    setState (value) {
      this.newCase.patient.address.state = value
    }
  }
}
</script>
